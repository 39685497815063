<template>
  <v-dialog v-model="show" persistent width="700" max-width="700">
    <v-card>
      <v-card-title class="text-h6">
        {{ $t('noFilterDialog.title') }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-subtitle class="text-h6">
        <v-icon class="mb-2 mr-2" color="warning"> mdi-alert </v-icon>
        {{ $t('noFilterDialog.subtitle') }}
      </v-card-subtitle>

      <v-card-text class="text-h7 mt-2">
        {{ $t('noFilterDialog.message') }}
      </v-card-text>

      <v-card-actions class="mt-4 justify-start">
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="handleChoice(1)">
          <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
          {{ $t('noFilterDialog.accept') }}
        </v-btn>

        <v-btn color="primary" text @click="handleChoice(2)">
          <v-icon dark left> mdi-arrow-left </v-icon
          >{{ $t('noFilterDialog.change') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false, // Inicialmente el diálogo está cerrado
    }
  },
  mounted() {
    // Abrimos el diálogo cuando el componente se monta
    this.show = true
  },
  methods: {
    handleChoice(choice) {
      this.$emit('userChoice', choice == 2 ? 'change' : 'accept')
      this.show = false
    },
  },
}
</script>
