<template>
  <v-dialog
    :value="true"
    persistent
    :max-width="
      isDelete || isForceDelete
        ? 570
        : isCreateCSV
        ? 1000
        : isCancel
        ? 500
        : 850
    "
  >
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{
              isDisplay || isCreateUnregistered || isUsers || isUpdateGD
                ? cif.empresa
                : ''
            }}
            {{ isCreate || isCreateCSV ? $t(title) : '' }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-tooltip
              bottom
              v-if="
                isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDisplay && isNotMainCif(cif)"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  class="primary secondary--text"
                  fab
                  small
                  @click="clickEditUserInfo"
                  id="edit-cif"
                >
                  <v-icon v-if="userIsEditing"> mdi-close </v-icon>
                  <v-icon v-else> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <div>
                {{
                  userIsEditing
                    ? $t('cancelUpdate')
                    : $t('companiesActions.title.update')
                }}
              </div>
            </v-tooltip>
            <v-btn v-else icon small @click="closeDialog" id="close-cif-dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="isCreateCSV">
        <v-col class="body-1">
          <span> {{ $t('massiveTitleCifs') }} </span>
          <span> {{ $t('csvColumns') }} </span>
          <br />
          <br />
          <span> <b>empresa</b>: {{ $t('csvCifs.company') }} </span>
          <br />
          <span>
            <b>tipoDocIdentidad</b>:
            {{ $t('csvCifs.identificationType') }}
          </span>
          <br />
          <span>
            <b>numeroDocIdentidad</b>:
            {{ $t('csvCifs.identificationNumber') }}
          </span>
          <br />
          <!--<span>
            <b>afiliado</b> ({{ $t('optional') }}): {{ $t('csvCifs.afiliado') }}
          </span>
          <br /> -->
          <span>
            <b>email</b> ({{ $t('optional') }}): {{ $t('csvCifs.email') }}
          </span>
          <br />
          <span>
            <b>telefono</b> ({{ $t('optional') }}): {{ $t('csvCifs.telefono') }}
          </span>
          <br />
          <br />
          <span> {{ $t('csvSeparation') }} </span>
          <br />
          <br />
          <p>
            {{ $t('checkTemplate') }}
            <v-btn
              class="ma-0 pa-0"
              small
              text
              chip
              rounded
              color="primary"
              @click="downloadTemplate"
            >
              {{ $t('downloadTemplate') }}
            </v-btn>
            {{ $t('checkTemplate2') }}
          </p>

          <v-file-input
            ref="csvfile"
            show-size
            accept=".csv"
            v-model="file"
            :label="$t('uploadCSV')"
            prepend-icon="mdi-paperclip"
            @change="onFileChange"
            :error-messages="errors"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <GroupAutocomplete
            v-if="
              groups?.length &&
              !isInGroupUser &&
              (isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies)
            "
            @setGroups="(groups) => (selectedGroups = groups)"
            :disabled="loading || !userIsEditing"
            :groups="groups"
          />

          <v-card-text v-if="isInGroupUser">
            <v-col class="body-1">
              <span>
                <b>{{ $t('associatedGroup') }}: </b></span
              >
              <v-chip>
                {{ groupName }}
              </v-chip>
              <br
            /></v-col>
          </v-card-text>

          <v-container v-if="log.length > 0">
            <v-btn
              chip
              color="primary"
              class="mb-3"
              @click="download"
              v-if="!log[0].includes($t('processingEmailCsv'))"
            >
              {{ $t('downloadLog') }}
            </v-btn>
            <v-col
              style="max-height: 300px; overflow-y: scroll; overflow-x: hidden"
            >
              <v-row
                class="px-5 font-weight-bold body-1"
                v-for="(item, i) in log"
                :key="i"
              >
                {{ item }}
              </v-row>
            </v-col>
          </v-container>
        </v-col>
      </v-card-text>
      <v-card-text v-if="isCancel" class="body-1">
        <v-col align="center">
          <h1 class="mt-1">{{ cif.empresa }}</h1>
          <h2 class="mt-3 mb-5">{{ cif.numeroDocIdentidad }}</h2>
          <span>{{ $t('cancelDelete') }}</span>
        </v-col>
      </v-card-text>

      <v-card-text v-else-if="isForceDelete">
        <v-col align="center" class="body-1">
          <h1 class="mt-1">{{ cif.empresa }}</h1>
          <br />
          <span>{{ $t('deleteWarning') }}</span>
          <br />
          <span>
            <b>
              {{ $t('deleteSureForce') }}
            </b>
          </span>
          <br />
          <div class="text-subtitle-2 mt-4">
            {{ $t('irreversibleForce') }}
          </div>
          <h2 class="mt-3 mb-3">{{ cif.numeroDocIdentidad }}</h2>
          <v-text-field
            class="mt-5"
            :label="$t('documentNumber')"
            :value="deleteCifField"
            @input="deleteCifField = $event"
            dense
            autocomplete="false"
            id="company-documentNumber"
            :disabled="loading"
            :rules="[
              maxCharacters(maxChars),
              notEmpty,
              equal(cif.numeroDocIdentidad, 'cifNotEqual'),
            ]"
          />
        </v-col>
      </v-card-text>

      <v-card-text v-else-if="isDelete" class="body-1">
        <v-col align="center" v-if="deleteStep === 1">
          <h2 class="mt-1">{{ cif.empresa }}</h2>
          <h3 class="mt-3 mb-5">{{ cif.numeroDocIdentidad }}</h3>
          <span>{{ $t('deleteWarning') }}</span>
          <br />
          <span>
            <b>
              {{ $t('deleteSure') }}
            </b>
          </span>
        </v-col>
        <v-col align="center" v-else-if="deleteStep === 2">
          <h2 class="mt-1">{{ $t('confirmDeletion') }}</h2>
          <h2 class="mt-3 mb-3">{{ cif.numeroDocIdentidad }}</h2>
          <span>{{ $t('typeTheCompanyCif') }}</span>
          <v-text-field
            class="mt-5"
            :label="$t('documentNumber')"
            :value="deleteCifField"
            @input="deleteCifField = $event"
            dense
            autocomplete="false"
            id="company-documentNumber"
            :disabled="loading"
            :rules="[
              maxCharacters(maxChars),
              notEmpty,
              equal(cif.numeroDocIdentidad, 'cifNotEqual'),
            ]"
          />
        </v-col>
      </v-card-text>

      <v-card-text
        v-else-if="
          isDisplay ||
          isCreate ||
          isCreateUnregistered ||
          isUpdate ||
          isUpdateGD
        "
      >
        <!-- Step 0 for the form -->
        <v-form
          ref="cifForm"
          v-model="validCifForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-container class="pa-0">
            <v-row justify="center" align="center" class="my-5">
              <v-alert
                v-if="
                  cifData.granDestinatario &&
                  cifData.certificateExpiration &&
                  isNotMainCif(cif)
                "
                outlined
                :color="certificateColor"
                class="mx-4 d-flex justify-center"
                width="500px"
                align="center"
              >
                <b>{{ certificateTitle }} </b>
                <span v-if="cif.certificateExpiration">
                  <i style="display: block">
                    {{ $t('certificateExpiration.expirationDate') }}
                    {{ $d(cif.certificateExpiration.toDate(), 'i18nDate') }}
                  </i>
                </span>
              </v-alert>
              <v-col
                class="mt-0 mb-0 pt-0 pb-0"
                cols="12"
                lg="10"
                v-if="!isUpdateGD"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('company')"
                      prepend-icon="mdi-city"
                      :value="cifData.empresa"
                      @input="cifData.empresa = $event"
                      dense
                      autocomplete="false"
                      id="company-name"
                      :disabled="loading || !userIsEditing"
                      :rules="[maxCharacters(maxChars), notEmpty]"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="mt-0 mb-0 pt-0 pb-0"
                cols="12"
                lg="10"
                v-if="!isUpdateGD"
              >
                <v-row align="center" justify="center">
                  <v-col cols="5">
                    <v-select
                      :items="documentTypes"
                      v-model="cifData.tipoDocIdentidad"
                      :label="$t('documentType')"
                      prepend-icon="mdi-card-account-details"
                      :value="cifData.tipoDocIdentidad"
                      @input="cifData.tipoDocIdentidad = $event"
                      dense
                      id="company-document-type"
                      :disabled="loading || !userIsEditing || !isCreate"
                      :rules="[maxCharacters(maxChars), notEmpty]"
                    >
                      <template v-slot:item="{ item }">
                        <span :id="'document-type-option-' + item">
                          {{ item }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      :label="$t('documentNumber')"
                      prepend-icon="mdi-numeric"
                      :value="cifData.numeroDocIdentidad"
                      @input="cifData.numeroDocIdentidad = $event.toUpperCase()"
                      dense
                      autocomplete="false"
                      id="company-documentNumber"
                      :disabled="loading || !userIsEditing || !isCreate"
                      :rules="[
                        maxCharacters(maxChars),
                        notEmpty,
                        unique(companiesCifs, 'companyExists'),
                        idNumber(
                          cifData.tipoDocIdentidad,
                          cifData.numeroDocIdentidad
                        ),
                      ]"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="mt-0 mb-0 pt-0 pb-0"
                cols="12"
                lg="10"
                v-if="
                  groups?.length &&
                  (isSuperAdminUser ||
                    isSuperOperatorUser ||
                    (isAdminAndCanManageCompanies && !isInGroupUser))
                "
              >
                <GroupAutocomplete
                  @setGroups="(groups) => (selectedGroups = groups)"
                  :disabled="loading || !userIsEditing"
                  :objectGroups="
                    !isCreate && !isCreateUnregistered ? companyGroups : []
                  "
                  :groups="isCreate || isCreateUnregistered ? groups : []"
                />
              </v-col>
              <!--<v-col cols="12" lg="10">
                <v-text-field
                  :label="$t('afiliado')"
                  :hint="$t('afiliadoHint')"
                  prepend-icon="mdi-human-queue"
                  :value="cifData.afiliado"
                  @input="cifData.afiliado = $event"
                  dense
                  autocomplete="false"
                  id="company-afiliado"
                  :disabled="loading || !userIsEditing"
                  :rules="[ssNumber(cifData.afiliado)]"
                />
              </v-col> -->
              <v-col
                class="mt-0 mb-0 pt-0 pb-0"
                cols="12"
                lg="10"
                v-if="!isUpdateGD"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      :label="$t('email')"
                      prepend-icon="mdi-email-outline"
                      :value="cifData.email"
                      @input="cifData.email = $event"
                      dense
                      autocomplete="false"
                      id="company-email"
                      :disabled="loading || !userIsEditing"
                      :rules="[email]"
                    />
                  </v-col>
                  <v-col cols="12" md="6" v-if="!isUpdateGD">
                    <v-text-field
                      :label="$t('phone')"
                      :hint="$t('hintPhone')"
                      prepend-icon="mdi-phone"
                      :value="cifData.telefono"
                      @input="cifData.telefono = $event"
                      dense
                      autocomplete="false"
                      id="company-phone"
                      :disabled="loading || !userIsEditing"
                      :rules="[phoneNumber]"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="mt-0 mb-0 pt-0 pb-0"
                cols="12"
                lg="10"
                v-if="
                  isEmpresaUser ||
                  (isUpdateGD && cifData.clientGD === $store.state.user.id)
                "
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" md="6">
                    <v-file-input
                      accept=".p12,.pfx"
                      v-model="file"
                      :label="$t('certificate')"
                      prepend-icon="mdi-file-certificate-outline"
                      :disabled="loading || (!isUpdateGD && !userIsEditing)"
                      :rules="
                        isCreate || isCreateUnregistered ? [notEmpty] : []
                      "
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :value="cifData.certPassword"
                      @input="cifData.certPassword = $event"
                      autocomplete="new-password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :label="$t('certPasword')"
                      prepend-icon="mdi-form-textbox-password"
                      :disabled="
                        loading ||
                        (!isUpdateGD && !userIsEditing) ||
                        (!isCreate && !isCreateUnregistered && !file)
                      "
                      @click:append="showPassword = !showPassword"
                      :rules="
                        isCreate || isCreateUnregistered || file
                          ? [notEmpty]
                          : []
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-col v-if="isUpdateGD">
                  <p class="body-2">{{ $t('legalTextAcceptCertificateGD') }}</p>
                  <p class="body-2">
                    {{ $t('legalTextAcceptCertificate2GD') }}
                  </p>
                  <v-checkbox
                    class="my-checkbox mr-3 mt-0 body-2"
                    v-model="certDisclamer"
                    :disabled="loading"
                    :label="$t('acceptTermsCertificateGD')"
                    id="accept-terms-certificateGD"
                  />
                </v-col>
              </v-col>
            </v-row>
            <v-row
              justify="start"
              align="center"
              class="mt-5"
              v-if="
                isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies
              "
            >
              <v-col cols="12" md="2" class="pt-0 pb-0"> </v-col>
              <v-col cols="12" md="5" class="pt-0 pb-0">
                <v-checkbox
                  class="my-checkbox mr-3 mt-0"
                  on-icon="mdi-mailbox"
                  :disabled="
                    !isNotificacionesActivasEmpresa || loading || !userIsEditing
                  "
                  v-model="cifData.notificacionesActivas"
                  :label="$t('notifications')"
                  id="company-notifications-input"
                  :error="
                    (isCreate || isCreateUnregistered) &&
                    tooManyCifsNotifications
                      ? true
                      : false
                  "
                >
                </v-checkbox>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="5">
                <v-checkbox
                  class="mr-3 mt-0"
                  on-icon="mdi-draw"
                  :disabled="
                    !isFirmasActivasEmpresa || loading || !userIsEditing
                  "
                  x-large
                  v-model="cifData.firmasActivas"
                  :label="$t('signatures')"
                  id="company-signatures-input"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row
              justify="start"
              align="center"
              class="mt-5"
              v-if="
                isDespachoUser &&
                cifData.notificacionesActivas &&
                (isSuperAdminUser ||
                  isSuperOperatorUser ||
                  isAdminAndCanManageCompanies)
              "
            >
              <v-col cols="12" md="2" class="pt-0 pb-0"> </v-col>
              <v-col cols="12" md="5" class="pt-0 pb-0">
                <b>{{ $t('isGDCompany?') }}</b>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="5">
                <v-checkbox
                  class="mr-3 mt-3"
                  on-icon="mdi-email-arrow-left"
                  :disabled="loading || !userIsEditing"
                  x-large
                  v-model="cifData.granDestinatario"
                  :label="$t('granDestinatario')"
                  id="company-isgd-input"
                  :error="
                    (isCreate || isCreateUnregistered) &&
                    tooManyCifsNotifications
                      ? true
                      : false
                  "
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-text v-else-if="isUsers">
        <v-list height="450" class="overflow-y-auto">
          <v-list-item
            v-for="(user, i) in cif.users"
            :key="i"
            tile
            :style="{
              'align-items': 'center',
              background: user.disabled ? '#EEEEEE' : 'white',
              'pointer-events': 'none',
            }"
          >
            <v-list-item-content>
              <v-container>
                <v-row justify="space-between" align="center">
                  <v-col class="mt-0 pt-0 pb-0 mb-0" cols="12" md="4">
                    <v-row justify="space-between" align="center">
                      <v-col cols="2">
                        <AppAvatar
                          :userName="`${user.name} ${user.surname}`"
                          :userAvatar="user.avatarURL"
                          avatarColor="primary"
                          initialsColor="secondary"
                          avatarSize="40"
                          iconSize="24"
                        />
                      </v-col>
                      <v-col cols="10">
                        <div class="mr-0 pr-0 body-1">
                          {{ `${user.name} ${user.surname}` }}
                        </div>
                        <div class="body-2">
                          {{ user.email }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-row justify="center">
                      <v-chip-group>
                        <v-chip
                          v-if="!user.disabled"
                          text-color="white"
                          small
                          :color="user.adminPermissions ? '#FFD600' : '#FAFAFA'"
                        >
                          <v-icon small> mdi-crown </v-icon>
                        </v-chip>
                        <v-chip
                          v-if="!user.disabled"
                          small
                          :color="
                            user.readNotification &&
                            user.cifsPermissions[cif.numeroDocIdentidad]
                              .notificacionesActivas
                              ? 'green'
                              : '#FAFAFA'
                          "
                          text-color="white"
                        >
                          <v-icon small :class="!isMobile && 'mr-1'">
                            mdi-eye
                          </v-icon>
                          <span v-if="!isMobile" class="ml-2">
                            {{ $t('readPermission') }}
                          </span>
                        </v-chip>
                        <v-chip
                          v-if="!user.disabled"
                          :color="
                            user.downloadNotification &&
                            user.cifsPermissions[cif.numeroDocIdentidad]
                              .notificacionesActivas
                              ? '#0a969c'
                              : '#FAFAFA'
                          "
                          small
                          text-color="white"
                        >
                          <v-icon small> mdi-download </v-icon>
                          <span v-if="!isMobile" class="ml-2">
                            {{ $t('downloadPermission') }}
                          </span>
                        </v-chip>
                        <v-chip
                          v-if="!user.disabled"
                          :color="
                            user.processNotification &&
                            user.cifsPermissions[cif.numeroDocIdentidad]
                              .notificacionesActivas
                              ? '#c4d100'
                              : '#FAFAFA'
                          "
                          small
                          text-color="white"
                        >
                          <v-icon small> mdi-email-open </v-icon>
                          <span v-if="!isMobile" class="ml-2">
                            {{ $t('processPermission') }}
                          </span>
                        </v-chip>

                        <v-chip
                          v-if="!user.disabled"
                          :color="
                            user.firmasActivas &&
                            user.cifsPermissions[cif.numeroDocIdentidad]
                              .firmasActivas
                              ? '#42A5F5'
                              : '#FAFAFA'
                          "
                          small
                          text-color="white"
                        >
                          <v-icon small :class="!isMobile && 'mr-1'">
                            mdi-draw
                          </v-icon>
                          <span v-if="!isMobile">
                            {{ $t('navigationPage.signatures') }}
                          </span>
                        </v-chip>
                        <v-chip
                          v-if="user.disabled"
                          small
                          color="#272727"
                          text-color="white"
                        >
                          <v-icon small> mdi-account-off </v-icon>

                          <span class="ml-2">
                            {{ $t('disabled') }}
                          </span>
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-if="isCreateCSV && step === 0">
        <AppPlanProgress
          v-if="isNotificacionesActivas"
          :current="cifsCountNotifications"
          :max="notificationsMaxCifs"
          :buffer="
            loading
              ? (100 / notificationsMaxCifs) * cifsCountNotifications
              : (100 / notificationsMaxCifs) *
                (cifsCountNotifications + cifsToUploadNotifications)
          "
          :stream="loading ? true : false"
          :progressByBuffer="loading ? false : true"
          textSize="h1"
          icon="mdi-mailbox"
        />
      </v-card-text>
      <br />
      <v-card-actions
        class="headline justify-center"
        v-if="
          isSuperAdminUser ||
          isSuperOperatorUser ||
          isAdminAndCanManageCompanies ||
          isUpdateGD
        "
      >
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          v-if="!isUsers && isNotMainCif(cif)"
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="
            loading ||
            (!isDelete &&
              !isForceDelete &&
              !isCreate &&
              !isCreateUnregistered &&
              !isCancel &&
              !isUpdateGD &&
              !userIsEditing) ||
            (!file && isCreateCSV) ||
            (tooManyCifsNotifications &&
              (isCreate || isCreateCSV || isCreateUnregistered)) ||
            (isDelete &&
              deleteStep === 2 &&
              deleteCifField !== cif.numeroDocIdentidad) ||
            (isForceDelete && deleteCifField !== cif.numeroDocIdentidad) ||
            ((isCreate || isDisplay || isCreateUnregistered) &&
              !cifData.notificacionesActivas &&
              !cifData.firmasActivas) ||
            (isUpdateGD && (!certDisclamer || !file || !cifData.certPassword))
          "
          @click="performAction"
          id="confirm-cif"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <EntityAlreadyExistsDialog
      v-if="showEntityAlreadyExistsDialog"
      :title="$t('cifAlreadyExists')"
      :text="$t('cifAlreadyExistsInAnotherGroup', [cifData.numeroDocIdentidad])"
      :objectId="alreadyExistingCifId"
      @closeDialog="
        () => {
          closeDialog()
          this.showEntityAlreadyExistsDialog = false
        }
      "
      action="addCifToGroup"
    />
    <!-- Dialog to create/update an specific client -->
    <ClientDialogGD
      v-if="clientDialog"
      :cif="cifData"
      action="createGD"
      @closeClientDialog="closeClientDialog"
      @confirmClientDialog="confirmClientDialog"
    />
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import { toBase64 } from '@/utils/file'
import csv from 'csvtojson'
import {
  createCIF,
  createCIFBulk,
  updateCIF,
  deleteCIF,
  cancelDeletion,
  programDeleteCIF,
} from '@/services/cifs-service'
import { getGroup } from '@/services/groups-service'
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'
import GroupAutocomplete from '@/components/groups/GroupAutocomplete'
import EntityAlreadyExistsDialog from '@/components/groups/EntityAlreadyExistsDialog'
import {
  checkCifExistence,
  removeCifFromGroup,
} from '@/services/groups-service'
import ClientDialogGD from './clients/ClientDialogGD'

export default {
  props: {
    cifs: Array,
    groups: Array,
    groupMap: Object,
    cif: Object,
    action: String,
    notificationsMaxCifs: Number,
    cifsCountNotifications: Number,
    title: String,
  },
  components: {
    AppPlanProgress,
    GroupAutocomplete,
    EntityAlreadyExistsDialog,
    ClientDialogGD,
  },
  async mounted() {
    // Si es create llenamos el diccionario con datos vacios
    this.cifData = {
      id: !this.isCreate ? this.cif.id : undefined,
      empresa: !this.isCreate ? this.cif.empresa : undefined,
      tipoDocIdentidad: !this.isCreate
        ? this.cif.tipoDocIdentidad?.toUpperCase()
        : undefined,
      numeroDocIdentidad: !this.isCreate
        ? this.cif.numeroDocIdentidad
        : undefined,
      afiliado: !this.isCreate ? this.cif.afiliado : undefined,
      email: !this.isCreate ? this.cif.email : undefined,
      telefono: !this.isCreate ? this.cif.telefono : undefined,
      notificacionesActivas: !this.isCreate
        ? this.cif.notificacionesActivas
        : false,
      firmasActivas: !this.isCreate ? this.cif.firmasActivas : false,
      granDestinatario: this.isEmpresaUser
        ? true
        : !this.isCreate
        ? this.cif.granDestinatario
        : false,
      clientGD: !this.isCreate ? this.cif.clientGD?.id : undefined,
      certificateExpiration: !this.isCreate
        ? this.cif.certificateExpiration?.toDate()
        : undefined,
      certBase64: undefined,
      certPassword: undefined,
    }
    // obtenemos el nombre del grupo asociado
    if (
      this.$store.state.user.groupsRef &&
      this.$store.state.user.groupsRef[0] &&
      !this.isSuperAdminUser &&
      !this.isSuperOperatorUser
    ) {
      this.selectedGroups = [this.$store.state.user.groupsRef[0].id]
      this.groupName = (
        await getGroup(this.$store.state.user.groupsRef[0])
      ).name
    }
  },
  data() {
    return {
      userIsEditing:
        this.action === 'create' ||
        this.action === 'create-csv' ||
        this.action === 'createUnregistered',
      validCifForm: false,
      validUserForm: false,
      maxChars: 100,
      cifData: {},
      documentTypes: ['DNI', 'NIE', 'CIF', 'NIF-M'],
      loading: false,
      showPassword: false,
      file: undefined,
      fileContent: undefined,
      log: [],
      cifsToUploadNotifications: 0,
      cifsToUploadSignatures: 0,
      errors: [],
      deleteCifField: '',
      step: 0,
      deleteStep: 1,
      selectedGroups: undefined,
      showEntityAlreadyExistsDialog: false,
      alreadyExistingCifId: undefined,
      certDisclamer: false,
      groupName: '',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result.split(',')[1])
        }
        reader.onerror = (error) => reject(error)
      })
    },
    download() {
      this.saveFile(this.log.join('\n'), 'log.txt')
    },
    downloadTemplate() {
      const text =
        'empresa;tipoDocIdentidad;numeroDocIdentidad;email;telefono\nempresa_1;cif;H49389346;empresa_1@email.com;+34666666666\nempresa_2;cif;H69013861;empresa_2@email.com;+34666666666\n'
      this.saveFile(text, 'plantilla_empresas.csv')
    },
    saveFile(text, name) {
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([text], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = name
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
    },
    onFileChange() {
      if (!this.file) {
        this.fileContent = ''
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => (this.fileContent = e.target.result)
      reader.readAsText(this.file, 'ISO-8859-1')
    },
    closeDialog() {
      this.$emit('closeCifDialog')
    },
    clickEditUserInfo() {
      this.userIsEditing = !this.userIsEditing
    },
    removeCertificate() {
      this.certificate = undefined
      this.certificateB64 = []
    },
    confirmClientDialog(id) {
      this.cifData.clientGD = id
    },
    closeClientDialog() {
      this.cifData.granDestinatario = false
    },

    async performAction() {
      if (
        this.step === 0 &&
        !this.$refs.cifForm?.validate() &&
        !this.isDelete &&
        !this.isCreateCSV &&
        !this.isCancel &&
        !this.isForceDelete
      )
        return
      else if (this.step === 2 && !this.$refs.userForm?.validate()) return

      this.loading = true

      // Solo el parent puede crear empresas.
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id

      try {
        if (this.isCreate || this.isCreateUnregistered) {
          const groupsIds = this.isInGroupUser
            ? [this.$store.state.user.groupsRef[0].id]
            : this.selectedGroups

          // Checking if the cif is already created only if the user is in a group and it is an operator
          // Super admins cannot be in a group so we just have to check the groupsRef variable
          if (this.isInGroupUser) {
            const cifExistence = await checkCifExistence(
              this.cifData.numeroDocIdentidad
            )

            // If the cif is already created and the current user is in a group
            if (cifExistence) {
              this.alreadyExistingCifId = cifExistence
              this.showEntityAlreadyExistsDialog = true
              return
            }
          }

          if (this.file)
            this.cifData.certBase64 = await this.getBase64(this.file)

          await createCIF(userId, {
            ...this.cifData,
            groupsIds,
          })
        } else if (this.isUpdate || this.isUpdateGD || this.isDisplay) {
          if (this.file)
            this.cifData.certBase64 = await this.getBase64(this.file)

          await updateCIF(userId, this.cifData.id, {
            ...this.cifData,
            groupsIds: this.selectedGroups,
          })
        } else if (this.isDelete) {
          if (this.deleteStep === 1) {
            this.deleteStep = 2
            this.loading = false
            return
          }

          if (this.isInGroupUser)
            await removeCifFromGroup(
              this.cifData.id,
              this.$store.state.user.groupsRef[0].id
            )

          await programDeleteCIF(userId, this.cifData.id)
        } else if (this.isCreateCSV) {
          const result = await createCIFBulk(
            userId,
            this.fileContent,
            this.selectedGroups
          )
          this.log = result.data.split('\n')
          this.$refs.csvfile.reset()
        } else if (this.isCancel) {
          cancelDeletion(userId, this.cif.id)
        } else if (this.isForceDelete) {
          await deleteCIF(userId, this.cifData.id)
        }

        // Mostramos snackbar con el mensaje de exito
        const message =
          this.action === 'delete' && this.isInGroupUser
            ? this.$t('companiesActions.success.deleteFromGroup')
            : this.$t('companiesActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        if (!this.isCreateCSV) this.closeDialog()
        this.loading = false
      } catch (error) {
        if (this.isCreateCSV && error.message.includes('deadline-exceeded')) {
          this.log[0] = this.$t('processingEmailCsv')
        } else {
          const message = getErrorText(error.message)
          this.setSnackbar({
            position: 'top',
            type: 'error',
            message,
          })
        }

        this.loading = false
        this.$refs.csvfile?.reset()
      }
    },
    /**
     * Checks if the cif is the main cif of the user.
     * @param {Object} cif Cif to check.
     */
    isNotMainCif(cif) {
      return (
        cif.id !==
        (this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id)
      )
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAdminAndCanManageCompanies',
      'isNotificacionesActivas',
      'isFirmasActivas',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
      'isOperatorUser',
      'isInGroupUser',
      'isEmpresaUser',
      'isDespachoUser',
    ]),
    companyGroups() {
      return this.groupMap[this.cif.id]?.map((cif) => cif.id)
    },
    companiesEmails() {
      return this.cifs
        .filter((cif) => this.cifData.id !== cif.id)
        .map((cif) => cif.email)
    },
    companiesCifs() {
      return this.cifs
        .filter((cif) => this.cifData.id !== cif.id)
        .map((cif) => cif.numeroDocIdentidad)
    },
    clientDialog() {
      return (
        !this.isEmpresaUser &&
        !this.cif?.granDestinatario &&
        this.cifData.granDestinatario &&
        !this.cifData.clientGD
      )
    },
    certificateColor() {
      return certificateColor(this.cif.certificateExpiration?.toDate())
    },
    certificateTitle() {
      return certificateTitle(this.cif.certificateExpiration?.toDate())
    },
    isCreate() {
      return this.action === 'create'
    },
    isCreateUnregistered() {
      return this.action === 'createUnregistered'
    },
    isCreateCSV() {
      return this.action === 'create-csv'
    },
    isUpdate() {
      return this.action === 'update'
    },
    isUpdateGD() {
      return this.action === 'updateGD'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    isCancel() {
      return this.action === 'cancelDelete'
    },
    isUsers() {
      return this.action === 'users'
    },
    isForceDelete() {
      return this.action === 'forceDelete'
    },
    tooManyCifsNotifications() {
      return this.isCreateCSV
        ? this.cifsCountNotifications + this.cifsToUploadNotifications >
            this.notificationsMaxCifs
        : !this.isEmpresaUser &&
          this.cifData.notificacionesActivas &&
          this.cifData.granDestinatario
        ? this.cifsCountNotifications + 5 > this.notificationsMaxCifs
        : this.cifsCountNotifications + this.cifData.notificacionesActivas >
          this.notificationsMaxCifs
    },
  },
  watch: {
    'cifData.granDestinatario': {
      handler: function (newValue) {
        if (newValue === false) this.cifData.clientGD = undefined
      },
      deep: true,
    },
    /**
     * Watcher to check when a certificate is added so that
     * it can be saved in the base64 format.
     */
    certificate: function (newValue) {
      if (newValue) {
        this.certificateB64 = []
        toBase64(newValue, 'certificateB64', this)
      }
    },
    async fileContent(newValue) {
      if (newValue !== undefined) {
        // Cargamos el CSV
        const companies = await csv({
          delimiter: ';',
        }).fromString(newValue)

        this.cifsToUploadNotifications = companies.length

        if (this.tooManyCifsNotifications) {
          this.errors = [this.$t('tooManyCifsCsv')]
          this.setSnackbar({
            position: 'top',
            type: 'warning',
            message: this.$t('tooManyCifsCsv'),
          })
        } else this.errors = []
      }
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
