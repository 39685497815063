import {
  Firestore,
  Database,
  NotificationsCollection,
  NotificationsDoc,
  NotificationsCreate,
  NotificationsRetrieve,
  NotificationsOpen,
  NotificationsSynchronize,
  NotificationsItem,
  NotificationsAck,
  NotificationsAnnexe,
  NotificationsExtra,
  NotificationsUploadFiles,
  NotificationsUploadExtraFiles,
  HistoryCollection,
  HistoryDoc,
  UsersDoc,
  ExportDmsNotifications,
  GetDocFromReference,
} from '@/firebase-exports'
import store from '@/store/index'
import i18n from '@/i18n'
import getErrorText from '@/utils/get-error-text'
import { getOperatorFilters, getUserOperatorsDict } from './operator-service'
import { openNotificationDemo } from './notification-demo-service'
import { updatePlan } from './plan-service'

/**
 * Crea una nueva notificación con los datos proporcionados y la marca como abierta.
 *
 * @param {Object} notificationData - Los datos de la notificación a crear.
 * @returns {Promise<string>} Una promesa que se resuelve con el ID de la notificación creada.
 */
export async function notificationCreate(notificationData) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const notificationId = (
    await NotificationsCreate({ userId, ...notificationData })
  ).data

  await NotificationsOpen({ id: notificationId })

  return notificationId
}

/**
 * Recupera las notificaciones actuales.
 *
 * @returns {Promise<Array>} Una promesa que se resuelve con un arreglo de notificaciones.
 */
export async function notificationsRetrieve() {
  return await NotificationsRetrieve()
}

/**
 * Actualiza las notificaciones del usuario. Si se fuerza la actualización o ha pasado más de una hora desde la última actualización, se recupera la última información de notificaciones.
 *
 * @param {boolean} [forceUpdate=false] - Indica si se debe forzar la actualización de notificaciones.
 */
export async function updateNotifications(forceUpdate = false) {
  if (
    store.state.user.notificacionesActivas &&
    !store.state.user.needsCertEmpresa &&
    (forceUpdate ||
      moreThanOneHour(store.state.user.lastRetrievedNotifications?.toDate()))
  ) {
    store.commit('setProgress', {
      message: 'updatingNotificationsText',
    })

    /* TODO anular y sustituir el bloque
    notificationsRetrieve()
      .then((result) => {
        store.commit('setProgress', { isDisplayed: false })

        if (result.data.errors) {
          const title = i18n.t('errorRetrieve')
          const message = i18n.t('errorRetrieveMessage')
          const error = i18n.t('genericError')
          //const error = i18n.t('errorRetreiveCifs') + result.data.errors.join(', ')

          store.commit('setDialog', { title, message, error, maxWidth: '550' })
        }
      })
      .catch((err) => {
        store.commit('setProgress', { isDisplayed: false })
        // El deadline-exceeded a veces puede ser por una mala conexión a Internet, evitamos mostrar error en este caso concreto
        // En caso de que haya fallado, en el siguiente login o refresco reintentará; si fue todo Ok, estarán todas las notificaciones
        if (!err.toString().includes('deadline-exceeded')) {
          const title = i18n.t('errorRetrieve')
          const message = i18n.t('errorRetrieveMessage')
          const error = getErrorText(err.toString())
          store.commit('setDialog', { title, message, error, maxWidth: '550' })
        }
      })

    updatePlan(true)
    */

    withTimeout(notificationsRetrieve(), 60000) // Llamada con timeout de 60 segundos
      .then((result) => {
        store.commit('setProgress', {
          message: 'updatingNotificationsText',
          isDisplayed: false,
        })

        if (result.data.errors) {
          const title = i18n.t('errorRetrieve')
          const message = i18n.t('errorRetrieveMessage')
          const error = i18n.t('genericError')
          //const error = i18n.t('errorRetreiveCifs') + result.data.errors.join(', ')

          store.commit('setDialog', { title, message, error, maxWidth: '550' })
        }
      })
      .catch((err) => {
        store.commit('setProgress', {
          message: 'updatingNotificationsText',
          isDisplayed: false,
        })
        if (err.toString().includes('Timeout after')) {
          return
        }

        // El deadline-exceeded a veces puede ser por una mala conexión a Internet, evitamos mostrar error en este caso concreto
        // En caso de que haya fallado, en el siguiente login o refresco reintentará; si fue todo Ok, estarán todas las notificaciones
        if (!err.toString().includes('deadline-exceeded')) {
          const title = i18n.t('errorRetrieve')
          const message = i18n.t('errorRetrieveMessage')
          const error = getErrorText(err.toString())
          store.commit('setDialog', { title, message, error, maxWidth: '550' })
        }
      })

    updatePlan(true)
  }
}

function withTimeout(promise, ms) {
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error(`Timeout after ${ms}ms`)), ms)
  )

  return Promise.race([promise, timeout]) // La que termine primero
}

/**
 * Actualiza una notificación específica con los datos proporcionados.
 *
 * @param {string} notificationId - ID de la notificación a actualizar.
 * @param {Object} notification - Los datos de actualización para la notificación.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta de la actualización.
 */
export async function updateNotification(notificationId, notification) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  var response = await Firestore.updateDoc(
    NotificationsDoc(userId, notificationId),
    notification
  )
  return response
}

/**
 * Actualiza la observación de una notificación en Firestore.
 *
 * @param {string} notificationId - ID de la notificación a actualizar.
 * @param {string} observation - Nueva observación a asignar a la notificación.
 * @returns {Promise<any>} - Promesa que se resuelve con la respuesta de la actualización.
 */
export async function updateObservation(notificationId, observation) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(NotificationsDoc(userId, notificationId), {
    observation: observation || Firestore.deleteField(),
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: observation
        ? 'notificationObservation'
        : 'notificationObservationDeleted',
      observation: observation || Firestore.deleteField(),
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Acepta una notificación por parte del manager.
 *
 * @param {string} notificationId - ID de la notificación a aceptar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de aceptación.
 */
export async function managerAcceptNotification(notificationId) {
  const batch = Firestore.writeBatch(Database)

  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  batch.update(NotificationsDoc(userId, notificationId), {
    managerAccepted: true,
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'managerAccept',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Rechaza una notificación por parte del manager.
 *
 * @param {string} notificationId - ID de la notificación a rechazar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de rechazo.
 */
export async function managerDeclineNotification(notificationId) {
  const batch = Firestore.writeBatch(Database)

  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  batch.update(NotificationsDoc(userId, notificationId), {
    managerRef: Firestore.deleteField(),
    managerAccepted: false,
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'managerDecline',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Asigna o desasigna un manager a una notificación.
 *
 * @param {string} notificationId - ID de la notificación.
 * @param {string} manager - ID del manager a asignar. Si es null, desasigna el manager actual.
 * @param {boolean} [isAdmin=false] - Indica si el manager asignado es un administrador.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de asignación.
 */
export async function assignManager(notificationId, manager, isAdmin = false) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(
    NotificationsDoc(userId, notificationId),
    manager
      ? { managerRef: UsersDoc(manager), managerAccepted: isAdmin }
      : {
          managerRef: Firestore.deleteField(),
          managerAccepted: Firestore.deleteField(),
        }
  )

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: manager ? 'assignManager' : 'unassignManager',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
      targetUserRef: manager ? UsersDoc(manager) : Firestore.deleteField(),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Recupera una notificación específica.
 *
 * @param {string} userId - ID del usuario.
 * @param {string} notificationId - ID de la notificación a recuperar.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos de la notificación.
 */
export async function getNotification(userId, notificationId) {
  var notification = undefined
  const docSnap = await Firestore.getDoc(
    NotificationsDoc(userId, notificationId)
  )
  const document = docSnap.data()
  if (document) {
    if (document.managerRef)
      document.manager = await GetDocFromReference(document.managerRef)

    if (document.fecha_puesta_disposicion)
      document.fecha_puesta_disposicion =
        document.fecha_puesta_disposicion.toDate()

    if (document.fecha_aceptacion)
      document.fecha_aceptacion = document.fecha_aceptacion.toDate()

    if (document.fecha_expiracion)
      document.fecha_expiracion = document.fecha_expiracion.toDate()

    if (document.alert) document.alert.date = document.alert.date.toDate()

    document.estadoOriginal = document.estado

    notification = { id: notificationId, ...document }
  }
  return notification
}

/**
 * Crea una subscripción a una notificación específica, actualizando el contexto con los cambios en tiempo real.
 *
 * @param {string} userId - ID del usuario.
 * @param {string} notificationId - ID de la notificación a la que suscribirse.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function getNotificationSubscription(
  userId,
  notificationId,
  context
) {
  try {
    const docSnap = await Firestore.getDoc(
      NotificationsDoc(userId, notificationId)
    )
    if (!docSnap.exists()) return
  } catch (error) {
    return
  }

  // Snapshot to check the notification
  var notificationUnsubscribe = Firestore.onSnapshot(
    NotificationsDoc(userId, notificationId),
    async (doc) => {
      const document = doc.data()
      // Setting the record in the context
      if (document) {
        if (document.managerRef)
          document.manager = await GetDocFromReference(document.managerRef)

        if (document.fecha_puesta_disposicion)
          document.fecha_puesta_disposicion =
            document.fecha_puesta_disposicion.toDate()

        if (document.fecha_aceptacion)
          document.fecha_aceptacion = document.fecha_aceptacion.toDate()

        if (document.fecha_expiracion)
          document.fecha_expiracion = document.fecha_expiracion.toDate()

        if (document.alert) document.alert.date = document.alert.date.toDate()

        document.estadoOriginal = document.estado

        context.notification = { id: notificationId, ...document }

        if (context.loadingNotification) context.loadingNotification = false
      }
    },
    (error) => {
      throw error
    }
  )

  return notificationUnsubscribe
}

/**
 * Filtra las notificaciones según los roles y permisos del usuario.
 *
 * @param {Array<Object>} notificationsParam - Las notificaciones a filtrar.
 * @param {Object} user - El usuario para el cual se realiza el filtrado.
 * @returns {Array<Object>} Un array de notificaciones filtradas.
 */
export function filterNotifications(notificationsParam, user) {
  let notifications = notificationsParam
  let operatorRoles = user.operatorRoles ?? []
  const isSuperAdmin = !user.parentRef

  // Si es administrador o si es "Ver Todo", no filtramos nada
  if (!isSuperAdmin && operatorRoles[0]?.name !== 'noFilter') {
    notifications = notificationsParam.filter((n) => {
      // En caso de se sea el responsable de la notificación, autorizarla
      if (n.managerRef && n.managerRef.id === user.id) return true
      //!operatorRoles.length > 0
      return operatorRoles.some((role) => {
        const filter = [
          ...role.administrationLevelAllowed,
          ...role.autonomicAllowed,
          ...role.sourceEntityAllowed,
        ]
        if (!role.isCustom) {
          // Filtros genéricos
          if (
            filter.includes(n.administrationLevel) ||
            filter.includes(n.autonomicCode) ||
            filter.includes(n.codigo_organismo_emisor) ||
            filter.includes(n.codigo_organismo_emisor_raiz)
          )
            return true
        } else {
          // Filtros específicos del usuario
          if (filter.length > 0) {
            const isInFilter =
              filter.includes(n.administrationLevel) ||
              filter.includes(n.autonomicCode) ||
              filter.includes(n.codigo_organismo_emisor) ||
              filter.includes(n.codigo_organismo_emisor_raiz)
            if (isInFilter && role.negativeEntities) return false
            else if (!isInFilter && !role.negativeEntities) return false
          }

          // If there is a missing field we do not check the types and we accept the notification
          if (!role.types || !role.comparisonType) return true

          return role.comparisonType == '=='
            ? role.types.some(
                // Comparing the type with the ones in the metadatas.
                // We just need one type in the metadata to be in the filter type to accept the notification.
                // If the notification does not have metadatas, we return false
                (type) =>
                  n.metadatas?.some((m) => m.tipo === type) ||
                  n.conceptoTipo === type
              )
            : role.types.every(
                // Comparing the type with the ones in the metadatas.
                // All the types in the notification have to be different than the ones in the filter.
                // If the notification does not have metadatas, we return true
                // (all the types are different because there are no types)
                (type) =>
                  (n.metadatas?.every((m) => m.tipo !== type) ?? true) &&
                  n.conceptoTipo !== type
              )
        }
      })
    })
  }

  return notifications
}

/**
 * Lista todas las notificaciones para un usuario específico, aplicando filtros y transformaciones necesarias.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} user - El objeto de usuario con detalles adicionales para el filtrado.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de notificaciones.
 */
export async function listAllNotifications(userId, user) {
  const operatorsObject = await getUserOperatorsDict(userId, true)

  const querySnap = await Firestore.getDocs(
    Firestore.query(
      NotificationsCollection(userId),
      Firestore.orderBy('fecha_puesta_disposicion', 'desc')
    )
  )

  let notifications = await Promise.all(
    querySnap.docs.map(async (doc) => {
      const document = doc.data()
      if (document.managerRef)
        document.manager =
          operatorsObject[document.managerRef.id] ??
          (await GetDocFromReference(document.managerRef))

      if (document.fecha_puesta_disposicion)
        document.fecha_puesta_disposicion =
          document.fecha_puesta_disposicion.toDate()

      if (document.fecha_aceptacion)
        document.fecha_aceptacion = document.fecha_aceptacion.toDate()

      if (document.fecha_expiracion)
        document.fecha_expiracion = document.fecha_expiracion.toDate()

      if (document.alert) document.alert.date = document.alert.date.toDate()

      document.estadoOriginal = document.estado

      return { id: doc.id, ...document }
    })
  )

  // Filtramos por CIF, si es operador los Ids no coinciden
  if (userId !== user.id) {
    const cifs = user.cifs.map((c) => c.numeroDocIdentidad)
    notifications = notifications.filter((notification) => {
      return (
        cifs.includes(notification.nif_titular) &&
        user.cifsPermissions[notification.nif_titular].notificacionesActivas
      )
    })
  }

  // Filtramos por filters
  return filterNotifications(notifications, user)
}

/**
 * Crea una subscripción a las notificaciones del usuario, actualizando los datos en tiempo real.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} user - El objeto de usuario para aplicar filtros de notificación.
 * @param {number} [days=90] - Número de días hacia atrás desde hoy para incluir en la subscripción.
 * @returns {Function} Una función para cancelar la subscripción.
 */
export async function notificationsSubscription(userId, user, days = 90) {
  const todaysDate = new Date()

  const from = new Date().setDate(todaysDate.getDate() - days)
  const fromDate = new Date(from)

  const to = new Date().setDate(todaysDate.getDate() + 1) // Add + 1 day to get new docs after initializing subscription
  const toDate = new Date(to)

  const operatorsObject = await getUserOperatorsDict(userId, true)

  const unsub = Firestore.onSnapshot(
    days != 0
      ? // Taking the last X days
        Firestore.query(
          NotificationsCollection(userId),
          Firestore.orderBy('fecha_puesta_disposicion', 'desc'),
          Firestore.startAt(toDate),
          Firestore.endAt(fromDate)
        )
      : // Taking all
        Firestore.query(
          NotificationsCollection(userId),
          Firestore.orderBy('fecha_puesta_disposicion', 'desc')
        ),
    async (querySnapshot) => {
      let notifications = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          if (document.managerRef) {
            document.manager =
              operatorsObject[document.managerRef.id] ??
              (await GetDocFromReference(document.managerRef))
          }

          if (document.fecha_puesta_disposicion)
            document.fecha_puesta_disposicion =
              document.fecha_puesta_disposicion.toDate()

          if (document.fecha_aceptacion)
            document.fecha_aceptacion = document.fecha_aceptacion.toDate()

          if (document.fecha_expiracion)
            document.fecha_expiracion = document.fecha_expiracion.toDate()

          if (document.alert) document.alert.date = document.alert.date.toDate()

          document.estadoOriginal = document.estado

          return { id: doc.id, ...document }
        })
      )

      // Filtramos por CIF, si es operador los Ids no coinciden
      if (userId !== user.id) {
        const cifs = user.cifs.map((c) => c.numeroDocIdentidad)
        notifications = notifications.filter((notification) => {
          return (
            cifs.includes(notification.nif_titular) &&
            user.cifsPermissions[notification.nif_titular].notificacionesActivas
          )
        })
      }

      // Filtramos por filters
      const finalNotifications = filterNotifications(notifications, user)

      // Guardamos en el store
      store.commit('setNotifications', finalNotifications)
    }
  )

  store.commit('setNotificationsUnsubscribe', unsub)
  return unsub
}

/**
 * Acepta una notificación específica.
 *
 * @param {Object} notification - La notificación a aceptar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de aceptación.
 */
export async function acceptNotification(notification) {
  return store.getters.isDemoEnv
    ? await openNotificationDemo({ id: notification.id })
    : (await NotificationsOpen({ id: notification.id })).data
}

/**
 * Sincroniza los identificadores del documento y de los items de una notificación específica.
 *
 * @param {Object} notification - La notificación a sincronizar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de sincronización.
 */
export async function synchronizeNotification(notification) {
  return (await NotificationsSynchronize({ id: notification.id })).data
}

/**
 * Abre un elemento específico de una notificación.
 *
 * @param {Object} notification - La notificación a abrir.
 * @param {string} item - El elemento de la notificación a abrir.
 * @param {boolean} shared - Indica si la notificación es compartida.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de apertura.
 */
export async function openNotification(notification, item, shared) {
  return (
    await NotificationsItem({
      id: notification.id,
      item,
      shared,
    })
  ).data
}

/**
 * Abre el acuse de recibo de una notificación.
 *
 * @param {Object} notification - La notificación a la que se accede.
 * @param {boolean} shared - Indica si la notificación es compartida.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de apertura.
 */
export async function openAck(notification, shared) {
  return (await NotificationsAck({ id: notification.id, shared })).data
}

/**
 * Abre un anexo de una notificación específica.
 *
 * @param {Object} notification - La notificación a la que se accede.
 * @param {Object} annexe - El anexo a abrir.
 * @param {boolean} shared - Indica si la notificación es compartida.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de apertura.
 */
export async function openAnnexe(notification, annexe, shared) {
  try {
    return (
      await NotificationsAnnexe({
        id: notification.id,
        annexe,
        shared,
      })
    ).data
  } catch (error) {
    console.log('Anexo no disponible', annexe.nombre)
  }
}

/**
 * Abre un extra de una notificación específica.
 *
 * @param {Object} notification - La notificación a la que se accede.
 * @param {Object} extra - El extra a abrir.
 * @param {boolean} shared - Indica si la notificación es compartida.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de apertura.
 */
export async function openExtra(notification, extra, shared) {
  try {
    return (
      await NotificationsExtra({
        id: notification.id,
        extra,
        shared,
      })
    ).data
  } catch (error) {
    console.log('Extra no disponible', extra.name)
  }
}

/**
 * Procesa una notificación específica, marcándola como en proceso.
 *
 * @param {string} notificationId - ID de la notificación a procesar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso.
 */
export async function notificationProcessing(notificationId) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(NotificationsDoc(userId, notificationId), {
    isProcessing: true,
    isCompleted: Firestore.deleteField(),
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'processing',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Completa el procesamiento de una notificación específica.
 *
 * @param {string} notificationId - ID de la notificación a completar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso.
 */
export async function notificationCompleted(notificationId) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(NotificationsDoc(userId, notificationId), {
    isProcessing: Firestore.deleteField(),
    isCompleted: true,
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'completed',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Revierte el estado de una notificación específica a su estado original.
 *
 * @param {string} notificationId - ID de la notificación a revertir.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso.
 */
export async function notificationRevert(notificationId) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(NotificationsDoc(userId, notificationId), {
    isProcessing: Firestore.deleteField(),
    isCompleted: Firestore.deleteField(),
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'reverted',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}

/*export function isToday(dateA, dateB) {
  return dateA.toDateString() === dateB.toDateString() ? true : false
}*/

export function moreThanOneHour(date) {
  const HOUR = 1000 * 60 * 60
  const anHourAgo = Date.now() - HOUR

  return date < anHourAgo
}

export function getNotificationStatus(notification) {
  const currentDate = new Date()

  //modificamos el campo Estado en los customizados en front para que sea filtrable en la tabla

  if (notification.isCompleted) {
    notification.estado = 'Completada'
    return {
      color: 'completed',
      status: 'notificationStatus.completed',
    }
  } else if (notification.isProcessing) {
    notification.estado = 'En Trámite'
    return {
      color: 'processing',
      status: 'notificationStatus.processing',
    }
  } else if (notification.estado == 'Aceptado') {
    //notification.estado == 'Aceptado'
    return {
      color: 'accept',
      status: 'notificationStatus.accepted',
    }
  } else if (notification.estado == 'Rechazada') {
    //notification.estado == 'Aceptado'
    return {
      color: 'error',
      status: 'notificationStatus.rejected',
    }
  } else if (!notification.document && notification.isComparecida) {
    notification.estado = 'Comparecida'
    return {
      color: 'appeared',
      status: 'notificationStatus.appeared',
    }
  } else {
    // Si la fecha de hoy es posterior a la emision + 10 dias es que ha expirado
    if (
      notification.estado == 'Expirada' ||
      (notification.fecha_expiracion &&
        notification.fecha_expiracion < currentDate)
    ) {
      // si no está abierto ni comparecido calculamos el estado en funcion de la fecha de emision
      notification.estado = 'Expirada'
      return {
        color: 'error',
        status: 'notificationStatus.expired',
      }
    } else if (
      notification.fecha_expiracion &&
      currentDate >
        new Date(notification.fecha_expiracion).setDate(
          new Date(notification.fecha_expiracion).getDate() - 3
        )
    ) {
      notification.estado = 'Urgente'
      return {
        color: 'warning',
        status: 'notificationStatus.urgent',
      }
    } else {
      notification.estado = 'Pendiente'
      return {
        color: 'warningLow',
        status: 'notificationStatus.pending',
      }
    }
  }
}

/**
 * Given the i18n status return the status.
 * @param {String} status i18n status.
 * @returns Status string.
 */
export function getNotificationStatusFromI18n(status) {
  switch (status) {
    case 'notificationStatus.pending':
      return 'Pendiente'

    case 'notificationStatus.urgent':
      return 'Urgente'

    case 'notificationStatus.expired':
      return 'Expirada'

    case 'notificationStatus.appeared':
      return 'Comparecida'

    case 'notificationStatus.accepted':
      return 'Aceptado'

    case 'notificationStatus.processing':
      return 'En Trámite'

    case 'notificationStatus.completed':
      return 'Completada'

    default:
      return ''
  }
}

export function getNotificationType(notification) {
  return notification.tipo_envio == '1'
    ? {
        name: 'comunication',
        icon: 'mdi-email-fast',
      }
    : {
        name: 'notification',
        icon: 'mdi-email-lock', //'mdi-email-alert'
      }
}

export async function canOperatorBeManagerWithFilters(operator, notification) {
  const isSuperAdmin = !operator.parentRef

  if (isSuperAdmin) return true

  let cifs = Object.entries(operator.cifsPermissions)
    .filter(([, v]) => v.notificacionesActivas)
    .map(([k]) => k)

  if (!cifs.includes(notification.nif_titular)) return false

  const operatorRoles = await getOperatorFilters(operator)

  return (
    filterNotifications([notification], { ...operator, operatorRoles }).length >
    0
  )
}

export async function canOperatorBeManager(operator, notification) {
  const isSuperAdmin = !operator.parentRef

  if (isSuperAdmin) return true

  let cifs = Object.entries(operator.cifsPermissions)
    .filter(([, v]) => v.notificacionesActivas)
    .map(([k]) => k)

  return cifs.includes(notification.nif_titular)
}

export async function possibleManagers(operators, notification, user) {
  const possibleManagers = [user]
  for (let i = 0; i < operators.length; i++) {
    const operator = operators[i]
    if (
      !operator.disabled &&
      (await canOperatorBeManager(operator, notification))
    )
      possibleManagers.push(operator)
  }

  return possibleManagers
}

/**
 * Sube documentos añadidos a una notificación específica al sistema DMS.
 *
 * @param {string} notificationId - ID de la notificación.
 * @param {Object} data - Datos de los documentos a subir.
 */
export async function uploadAddedDocumentsToDMS(notificationId, data) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await NotificationsUploadExtraFiles({
    userId,
    notificationId,
    ...data,
  })
}

/**
 * Sube archivos de 'comparecencia' a una notificación específica.
 *
 * @param {string} notificationId - ID de la notificación.
 * @param {...} - Otros parámetros relevantes para la subida de archivos.
 */
export async function uploadComparecidaFiles(
  notificationId,
  mainDocs,
  acuseDocs,
  annexesDocs,
  fecha
) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await NotificationsUploadFiles({
    userId,
    notificationId,
    mainDocs,
    acuseDocs,
    annexesDocs,
    fecha,
  })

  await NotificationsOpen({ id: notificationId })
}

/**
 * Exporta notificaciones al sistema DMS.
 *
 * @param {Array<Object>} notifications - Notificaciones a exportar.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de exportación.
 */
export async function exportDmsNotifications(notifications) {
  return await ExportDmsNotifications({
    notifications,
  })
}

/**
 * Obtiene los usuarios que pueden ver una notificación específica.
 *
 * @param {Array<Object>} users - Lista de usuarios a considerar.
 * @param {Object} notification - La notificación a evaluar.
 * @returns {Promise<Array>} Una promesa que se resuelve con los usuarios que pueden ver la notificación.
 */
export async function usersThatCanViewNotification(users, notification) {
  // TODO esto es fake, hay que llamar a la funcion del servicio
  // console.log('notification=', notification)
  // const numOperators = Math.floor(Math.random() * 100 - 1)
  // for (let index = 0; index < numOperators; index++) {
  //   operators.push('Operador num. ' + index)
  // }

  let operators = await Promise.all(
    users.map(async (operator) => {
      const canBeManager = await canOperatorBeManagerWithFilters(
        operator,
        notification
      )
      const possibleManager = {
        id: operator.id,
        name: `${operator?.name} ${operator?.surname}`,
        avatar: operator?.avatarURL,
        canBeManager: canBeManager,
        disabled: operator?.disabled,
      }
      //console.log('possibleManager: ', possibleManager)
      return possibleManager
    })
  ).catch((reason) => {
    console.log('ERROR: ', reason)
  })

  operators = operators.filter((operator) => {
    /*console.log(
      'operators.filter(): name=' +
        operator.name +
        ' canBeManager=' +
        operator.canBeManager +
        ' disabled=' +
        operator.disabled +
        ' return=' +
        (operator.canBeManager && !operator.disabled)
    )*/
    return operator.canBeManager && !operator.disabled
  })

  //console.log('notification.manager.disabled=', notification.manager.disabled)
  if (
    notification.manager &&
    notification.manager.parentRef &&
    notification.manager.disabled === false &&
    (operators.length === 0 ||
      operators.find((oper) => oper.id === notification.manager.id) === false)
  ) {
    const manager = {
      id: notification.manager?.id,
      name: `${notification.manager?.name} ${notification.manager?.surname}`,
      avatar: notification.manager?.avatarURL,
    }
    operators.push(manager)
  }

  return operators
}

/**
 * Obtiene los usuarios que pueden ver una notificación específica.
 *
 * @param {Array<Object>} users - Lista de usuarios a considerar.
 * @param {Object} notification - La notificación a evaluar.
 * @returns {Promise<Array>} Una promesa que se resuelve con los usuarios que pueden ver la notificación.
 */
export async function operatorsCanViewNotification(users, notifications) {
  return notifications.filter(async (item) => {
    item.operators = await usersThatCanViewNotification(users, item)
    return item
  })
}

/**
 * Actualiza el NIF Titular de una notificación en Firestore.
 *
 * @param {string} notificationId - ID de la notificación a actualizar.
 * @param {string} nif - Nif del titular a asignar a la notificación.
 * @returns {Promise<any>} - Promesa que se resuelve con la respuesta de la actualización.
 */
export async function updateNifTitular(notificationId, nif) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  batch.update(NotificationsDoc(userId, notificationId), {
    nif_titular: nif,
  })

  batch.set(
    HistoryDoc(
      userId,
      'notifications',
      notificationId,
      Firestore.doc(HistoryCollection(userId, 'notifications', notificationId))
        .id
    ),
    {
      actionType: 'notificationNifTitularAdded',
      nif_titular: nif,
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
    },
    { merge: true }
  )

  return await batch.commit()
}
