<template>
  <div>
    <v-card data-v-step="3" outlined>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="6" xl="auto" class="order-0">
            <v-icon>mdi-account</v-icon>
            <span class="headline">{{ $t('operators') }}</span>
            <span class="subtitle-2 font-weight-light">
              ({{ operatorsCount }})
            </span>
            <!-- warning que indica que hay operadores sin filtros y cuantos -->
            <div
              v-if="operatorsWithoutFiltersCount > 0"
              style="display: inline-flex; align-items: center"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon class="mb-2 ml-2 mr-0" color="warning">
                      mdi-alert
                    </v-icon>
                  </span>
                </template>
                <!-- Contenido del tooltip del icono -->
                <span>{{ $t('operatorsWithoutFilters') }}</span>
              </v-tooltip>
              <!-- indica cuantos hay -->
              <span class="subtitle-2 font-weight-light">
                ({{ operatorsWithoutFiltersCount }})
              </span>
            </div>
            <DialogButton section="operators"> </DialogButton>
          </v-col>

          <v-col
            class="d-flex justify-end align-center order-1 order-xl-2"
            cols="6"
            xl="auto"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="isAdminUser"
                  fab
                  small
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                  id="export-operators"
                  class="primary secondary--text"
                  @click.stop="exportDataOperators()"
                >
                  <v-icon> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('operatorActions.title.exportDataOperators') }}
              </div>
            </v-tooltip>
            <v-menu left offset-y v-if="isAdminUser && !isInGroupUser">
              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
                  >
                    <v-btn
                      class="ml-3 primary secondary--text"
                      fab
                      elevation="1"
                      small
                      v-bind="{ ...attrsMenu, ...attrsTooltip }"
                      v-on="{ ...onMenu, ...onTooltip }"
                      @click="openOperatorDialog('create-csv')"
                      :disabled="loading"
                      id="create-operator-csv"
                    >
                      <v-icon id="create-operator-csv">
                        mdi-file-delimited
                      </v-icon>
                    </v-btn>
                  </template>
                  <div>
                    {{ $t('operatorActions.title.create-csv') }}
                  </div>
                </v-tooltip>
              </template>
            </v-menu>
            <v-menu left offset-y>
              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
                  >
                    <v-btn
                      class="ml-3 primary secondary--text"
                      fab
                      elevation="1"
                      small
                      v-bind="{ ...attrsMenu, ...attrsTooltip }"
                      v-on="{ ...onMenu, ...onTooltip }"
                      @click="openOperatorDialog('create')"
                      :disabled="loading"
                      id="create-operator"
                    >
                      <v-icon id="create-operator">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <div>
                    {{ $t('operatorActions.title.create') }}
                  </div>
                </v-tooltip>
              </template>
            </v-menu>
          </v-col>
        </v-row>

        <v-list-item>
          <v-list-item-content>
            <v-row class="pa-3">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                clearable
                :label="$t('searchOperators')"
                single-line
                hide-details
                :disabled="loading"
              />
              <v-checkbox
                class="ml-1"
                v-model="disabledExclude"
                :label="$t('excludeDisabled')"
              />
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <AppSkeleton :condition="loading" type="operatorList">
          <template v-slot:skeleton-content>
            <v-list height="450" class="overflow-y-auto">
              <template v-if="operators.length !== 0">
                <v-container v-if="!operators.length" fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col cols="12" align="center" justify="center">
                      {{ $t('emptySearch') }}
                    </v-col>
                  </v-row>
                </v-container>
                <v-list-item
                  v-for="(item, i) in pageOperators"
                  :key="i"
                  @click="
                    item.disabled ? '' : openOperatorDialog('display', item)
                  "
                  tile
                  :style="{
                    'align-items': 'center',
                    opacity: item.disabled ? '0.7' : '1',
                  }"
                >
                  <v-list-item-content>
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <v-col class="mt-0 pt-0 pb-0 mb-0" cols="12" lg="4">
                          <v-row justify="space-between" align="center">
                            <v-col cols="auto">
                              <v-tooltip
                                right
                                :max-width="250"
                                :disabled="
                                  !(
                                    item.notificacionesActivas &&
                                    item.filtersRef &&
                                    item.filtersRef.length === 0
                                  )
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <AppAvatar
                                      :userName="`${item.name} ${item.surname}`"
                                      :userAvatar="item.avatarURL"
                                      avatarColor="primary"
                                      initialsColor="secondary"
                                      avatarSize="40"
                                      iconSize="24"
                                      :showWarn="
                                        item.notificacionesActivas &&
                                        item.filtersRef &&
                                        item.filtersRef.length === 0
                                      "
                                      :overlapIconColor="'warning'"
                                    />
                                  </span>
                                </template>
                                <!-- Contenido del tooltip -->
                                <span>{{ $t('noFilterDialog.subtitle') }}</span>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="8">
                              <div class="mr-0 pr-0 body-1">
                                {{ `${item.name} ${item.surname}` }}
                              </div>
                              <div class="body-2">
                                {{ item.email }}
                              </div>
                            </v-col>
                            <v-col cols="2" class="ma-0 pa-0">
                              <v-btn
                                elevation="2"
                                icon
                                id="operator-activity"
                                @click.stop="
                                  openOperatorDialog('activity', item)
                                "
                              >
                                <v-icon>mdi-clipboard-text-clock</v-icon>
                                <!--<span v-if="!isMobile">{{ $t('activity') }}</span>-->
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col data-v-step="4" cols="12" md="9" lg="6">
                          <v-row justify="center">
                            <v-chip-group>
                              <v-chip
                                v-if="!item.disabled"
                                text-color="white"
                                small
                                :style="
                                  item.adminPermissions
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                "
                                :color="'#FFD600'"
                              >
                                <v-icon small> mdi-crown </v-icon>
                              </v-chip>
                              <v-chip
                                v-if="!item.disabled"
                                small
                                :style="
                                  item.notificacionesActivas &&
                                  item.readNotification
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                "
                                :color="'green'"
                                text-color="white"
                              >
                                <v-icon small :class="!isMobile && 'mr-1'">
                                  mdi-eye
                                </v-icon>
                                <span v-if="!isMobile" class="ml-2">
                                  {{ $t('readPermission') }}
                                </span>
                              </v-chip>
                              <v-chip
                                v-if="!item.disabled"
                                :style="
                                  item.notificacionesActivas &&
                                  item.downloadNotification
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                "
                                :color="'#0a969c'"
                                small
                                text-color="white"
                              >
                                <v-icon small> mdi-download </v-icon>
                                <span v-if="!isMobile" class="ml-2">
                                  {{ $t('downloadPermission') }}
                                </span>
                              </v-chip>
                              <v-chip
                                v-if="!item.disabled"
                                :style="
                                  item.notificacionesActivas &&
                                  item.processNotification
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                "
                                :color="'#c4d100'"
                                small
                                text-color="white"
                              >
                                <v-icon small> mdi-email-open </v-icon>
                                <span v-if="!isMobile" class="ml-2">
                                  {{ $t('processPermission') }}
                                </span>
                              </v-chip>

                              <v-chip
                                v-if="!item.disabled"
                                :style="
                                  item.firmasActivas
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                "
                                :color="'#42A5F5'"
                                small
                                text-color="white"
                              >
                                <v-icon small :class="!isMobile && 'mr-1'">
                                  mdi-draw
                                </v-icon>
                                <span v-if="!isMobile">
                                  {{ $t('navigationPage.signatures') }}
                                </span>
                              </v-chip>
                              <v-chip
                                v-if="item.disabled"
                                small
                                color="#272727"
                                text-color="white"
                              >
                                <v-icon small> mdi-account-off </v-icon>

                                <span class="ml-2">
                                  {{ $t('disabled') }}
                                </span>
                              </v-chip>
                            </v-chip-group>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="3" lg="2">
                          <v-row :justify="isMobile ? 'center' : 'end'">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-show="!item.disabled && isAllowed2FA"
                                  class="ma-1"
                                  elevation="1"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="
                                    openOperatorDialog('multifactor', item)
                                  "
                                >
                                  <v-icon
                                    :color="item.multiFactor ? 'green' : 'grey'"
                                  >
                                    mdi-two-factor-authentication
                                  </v-icon>
                                </v-btn>
                              </template>
                              <div>
                                {{ $t('operatorActions.title.multifactor') }}
                              </div>
                            </v-tooltip>

                            <v-btn
                              v-if="!item.disabled"
                              class="ma-1"
                              elevation="1"
                              icon
                              @click.stop="openOperatorDialog('update', item)"
                            >
                              <v-icon> mdi-pencil </v-icon>
                            </v-btn>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-show="!item.disabled"
                                  class="ma-1"
                                  elevation="1"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  id="disable-operator"
                                  @click.stop="
                                    openOperatorDialog('delete', item)
                                  "
                                  :disabled="isLoggedOperator(item.id)"
                                >
                                  <v-icon> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <div>
                                {{ $t('operatorActions.title.delete') }}
                              </div>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-show="item.disabled"
                                  class="ma-1"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="
                                    openOperatorDialog('enable', item)
                                  "
                                  id="enable-operator"
                                  elevation="1"
                                  :disabled="isLoggedOperator(item.id)"
                                >
                                  <v-icon> mdi-lock-open-check </v-icon>
                                </v-btn>
                              </template>
                              <div>
                                {{ $t('operatorActions.title.enable') }}
                              </div>
                            </v-tooltip>
                            <!-- Icono vació por espacios -->
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-container v-else fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col cols="12" align="center" justify="center">
                    {{ $t('noOperatorsCreated') }}
                  </v-col>
                </v-row>
              </v-container>
            </v-list>
          </template>
        </AppSkeleton>
      </v-container>
      <v-divider />
      <v-card-actions class="headline justify-end" style="max-height: 50px">
        <PaginateBar
          :initialPageSize="10"
          :pageSizes="[10, 25, 50, 100]"
          :search="search"
          :items="operatorsFiltered"
          @setPage="(value) => (page = value)"
          @setPageSize="(value) => (pageSize = value)"
        />
      </v-card-actions>
    </v-card>
    <OperatorDialog
      v-if="showDialog"
      :action="actionDialog"
      :operators="operators"
      :actionDialog="actionDialog"
      :operatorProp="operatorToEdit"
      :companies="companies"
      :companiesDict="companiesDict"
      :filters="filters"
      :groupsMap="groupsMap"
      :groups="groups"
      :cifsMap="cifsMap"
      @closeOperatorDialog="closeOperatorDialog"
      @confirmOperatorDialog="confirmOperatorDialog"
    />
  </div>
</template>

<script>
import PaginateBar from '@/components/commons/PaginateBar'
import OperatorDialog from '@/components/operators/OperatorDialog'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { listFilters } from '@/services/filter-service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    operators: Array,
    companies: Array,
    cifsMap: Object,
    filters: Array,
    groupsMap: Object,
    groups: Array,
    loading: Boolean,
  },
  components: {
    OperatorDialog,
    DialogButton,
    PaginateBar,
  },
  mounted() {
    // Construimos el diccionario de las empresas
    this.companies.forEach(
      (company) => (this.companiesDict[company.id] = { ...company })
    )
  },
  data() {
    return {
      page: 0,
      pageSize: 0,
      search: '',
      showDialog: false,
      actionDialog: '',
      operatorToEdit: {},
      companiesToEdit: {},
      companiesDict: {},
      operatorCIFs: [],
      disabledExclude: false,
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isAllowed2FA',
      'isInGroupUser',
      'isAdminUser',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
    ]),
    operatorsCount() {
      return this.operators.length
    },
    operatorsWithoutFiltersCount() {
      return this.operatorsFiltered.reduce((count, item) => {
        return item.notificacionesActivas &&
          item.filtersRef &&
          item.filtersRef.length === 0
          ? count + 1
          : count
      }, 0)
    },
    operatorsFiltered() {
      const searchUpperCase = this.search ? this.search.toUpperCase() : ''
      let filtered = this.operators?.filter(
        (operator) =>
          operator.name?.toUpperCase().includes(searchUpperCase) ||
          operator.surname?.toUpperCase().includes(searchUpperCase) ||
          operator.email?.toUpperCase().includes(searchUpperCase)
      )
      if (this.toDeleteExclude) {
        filtered = filtered?.filter((cif) => !cif.deletionDate)
      }

      return this.disabledExclude
        ? filtered.filter((operator) => !operator.disabled)
        : filtered
    },
    pageOperators() {
      return this.operatorsFiltered.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      )
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Checks if the the operator sent is the logged user.
     * @param {String} operatorId Operator object.
     */
    isLoggedOperator(operatorId) {
      return operatorId === this.$store.state.user.id
    },
    openOperatorDialog(action, item = {}) {
      // Not allowing operator to open the delete dialog of himself
      if (action === 'delete' && this.isLoggedOperator(item.id)) return

      if (action === 'create') this.operatorToEdit = {}

      // Establecemos la información necesaria para abrir el dialog
      this.showDialog = true
      this.operatorToEdit = item
      this.actionDialog = action
    },
    closeOperatorDialog() {
      this.showDialog = false
      this.companiesData = {}
      this.actionDialog = ''
    },
    async confirmOperatorDialog() {},
    filterOperators() {
      this.operatorsFiltered = this.operators.filter(({ name, email }) => {
        const usernameLow = name.toLowerCase()
        const emailLow = email.toLowerCase()
        return (
          usernameLow.toLowerCase().includes(this.search.toLowerCase()) ||
          emailLow.includes(this.search.toLowerCase())
        )
      })
    },
    /**
     * Función asíncrona que exporta datos de los operadores en un csv
     */
    async exportDataOperators() {
      var operatorsData = [...this.operators]
      const filters = await listFilters()

      //se recooren todos los operadores
      var exportOperators = operatorsData.map((operator) => {
        if (operator.groupsRef?.length) {
          operator.group = this.groupsMap[operator.groupsRef[0].id].name
        }

        if (operator.filtersRef) {
          const filtersRef = operator.filtersRef.map((filterRef) => {
            const filter = filters.find((filter) => filter.id === filterRef.id)
            return filter.isCustom
              ? filter.name
              : this.$t('filters.' + filter.name)
          })

          operator.filtersRefData = filtersRef
        }

        //Se construyen los arrays de cifs con notificaciones y firmas habilitadas para este operador
        const firmasActivasData = []
        const notificacionesActivasData = []
        if (operator.cifsPermissions) {
          for (const [key, value] of Object.entries(operator.cifsPermissions)) {
            if (value.firmasActivas) {
              firmasActivasData.push(key)
            }
            if (value.notificacionesActivas) {
              notificacionesActivasData.push(key)
            }
          }
        }
        operator.notificacionesActivasCifs = notificacionesActivasData
        operator.firmasActivasCifs = firmasActivasData

        return operator
      })

      if (exportOperators.length < 1) {
        const message = this.$t('nothingToExport')
        this.setSnackbar({ position: 'top', type: 'info', message })
        return
      }
      const replacer = (key, value) => (value === null ? '' : value) // valores undefined

      const header = [
        //header auxiliar con el nombre de las variables
        'name',
        'surname',
        'email',
        'disabled',
        'group',
        'filtersRefData',
        'adminPermissions',
        'notificacionesActivas',
        'readNotification',
        'downloadNotification',
        'processNotification',
        'firmasActivas',
        'notificacionesActivasCifs',
        'firmasActivasCifs',
      ]

      const header2 = [
        //header a mostrar en el csv
        this.$i18n.t('csv.operators.name'),
        this.$i18n.t('csv.operators.surname'),
        this.$i18n.t('csv.operators.email'),
        this.$i18n.t('csv.operators.disabled'),
        this.$i18n.t('csv.operators.group'),
        this.$i18n.t('csv.operators.filtersRef'),
        this.$i18n.t('csv.operators.adminPermissions'),
        this.$i18n.t('csv.operators.notificacionesActivas'),
        this.$i18n.t('csv.operators.readNotification'),
        this.$i18n.t('csv.operators.downloadNotification'),
        this.$i18n.t('csv.operators.processNotification'),
        this.$i18n.t('csv.operators.firmasActivas'),
        this.$i18n.t('csv.operators.notificacionesActivasCifs'),
        this.$i18n.t('csv.operators.firmasActivasCifs'),
      ]
      const csv = [
        header2.join(';'), // asignamos separador
        ...exportOperators.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        ),
      ].join('\r\n')
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([csv], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = 'operators.csv'
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
      const message = this.$t('exportComplete')
      this.setSnackbar({ position: 'top', type: 'success', message })
      return
    },
  },
  watch: {
    companies() {
      this.companiesDict = {}
      this.companies.forEach(
        (company) => (this.companiesDict[company.id] = { ...company })
      )
    },
  },
}
</script>

<style lang="scss"></style>
