<template>
  <v-data-table
    v-model="selectedNotificationsRows"
    :loading="loading"
    :headers="computedHeaders"
    :items="notifications"
    @click:row="handleRowClick"
    :items-per-page="5"
    :footer-props="{ 'items-per-page-options': [5, 25, 50 /*-1*/] }"
    class="elevation-1"
    :show-select="showSelect"
  >
    <template v-slot:[`item.id`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{
              item.id.substring(0, 3) +
              '...' +
              item.id.substring(item.id.length - 3)
            }}
          </span>
        </template>
        <div width="300">
          {{ item.id }}
        </div>
      </v-tooltip>
    </template>
    <template v-slot:[`item.fecha_puesta_disposicion`]="{ item }">
      {{ $d(item.fecha_puesta_disposicion, 'i18nDate') }}
    </template>
    <template v-slot:[`item.estado`]="{ item }">
      <v-chip
        class="ma-2 white--text"
        :color="getNotificationStatus(item).color"
      >
        {{ $t(getNotificationStatus(item).status) }}
      </v-chip>
    </template>

    <!-- item Notificaciones sin NIF_Titular -->
    <template v-slot:[`item.nif_titular`]="{ item }">
      <span v-if="!isEmptyNifTitular(item)">
        {{ item.nif_titular }}
      </span>
      <span v-else class="without-nif-alarm">
        {{ $t('notificationWithoutNifTitular') }}
      </span>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
import { getNotificationStatus } from '@/services/notification-service'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    mobileHeaders: {
      type: Array,
      default: () => [],
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    selectedNotifications: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedNotificationsRows: [],
    }
  },
  mounted() {
    this.selectedNotificationsRows = this.selectedNotifications
  },
  methods: {
    /**
     * Shortens the text.
     * @param {String} text Text to shorten.
     * @param {Number} size Size of the text.
     */
    getShortText(text, size = 12) {
      return text.length > size ? text.substring(0, size - 3) + '...' : text
    },
    /**
     * Emits the set event when the row is clicked
     * @param {Object} item Notification clicked.
     */
    handleRowClick(item) {
      this.$emit('clickRow', item)
      const index = this.selectedNotificationsRows.findIndex(
        (n) => n.id === item.id
      )
      if (index > -1) this.selectedNotificationsRows.splice(index, 1)
      else this.selectedNotificationsRows.push(item)
    },
    /**
     * Gets the notification status
     * @param {Object} notification Notification document.
     */
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    isEmptyNifTitular(item) {
      return !(item.nif_titular && item.nif_titular.trim().length > 0)
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    computedHeaders() {
      const headers = [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: this.$t('dispatchDate'),
          value: 'fecha_puesta_disposicion',
          width: '20px',
        },
        { text: this.$t('company'), value: 'nombre_titular' },
        { text: 'NIF', value: 'nif_titular' },
        { text: this.$t('issuer'), value: 'concepto', width: '300px' },
        { text: this.$t('state'), value: 'estado' },
        {
          text: this.$t('issuingAuthority'),
          value: 'nombre_organismo_emisor',
        },
      ]

      return this.isMobile && this.mobileHeaders.length > 0
        ? headers.filter((h) => this.mobileHeaders.includes(h.value))
        : headers.filter((h) => this.headers.includes(h.value))
    },
  },
  watch: {
    selectedNotificationsRows: function (newValue, previousValue) {
      if (newValue && previousValue) this.$emit('setSelectedRows', newValue)
    },
  },
}
</script>

<style>
.without-nif-alarm {
  background-color: #d70a0a;
  color: white;
  font-weight: bold;
  padding: 6px 6px;
  border-radius: 4px;
  white-space: nowrap;
  width: auto;
}
</style>
