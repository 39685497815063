<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent max-width="700">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            {{ $t('editNifDialog.title') }}
          </v-card-title>

          <div v-if="!isAcceptedBtn && !isIgnoredBtn">
            <v-card-subtitle class="text-h7 d-flex justify-center align-center">
              {{ $t('editNifDialog.subtitle') }}
            </v-card-subtitle>

            <template>
              <v-container fluid>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="8" class="pr-8">
                    <v-text-field
                      data-v-step="104"
                      class="my-0 py-0"
                      v-model="search"
                      clearable
                      prepend-icon="mdi-magnify"
                      :label="$t('searchField')"
                      single-line
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="filteredCifs"
                      :items-per-page="8"
                      @click:row="selectedRow"
                      :sort-by="'empresa'"
                      :sort-desc="false"
                      :footer-props="{
                        'items-per-page-options': [8, 25, 50 /*-1*/],
                      }"
                      class="elevation-1 mx-4 px-4"
                    >
                      <template v-slot:[`item.numeroDocIdentidad`]="{ item }">
                        <div
                          :class="{
                            'selected-row':
                              selectedCif?.numeroDocIdentidad ===
                              item.numeroDocIdentidad,
                          }"
                          style="display: flex; justify-content: space-between"
                        >
                          {{ item.numeroDocIdentidad }}
                        </div>
                      </template>

                      <template v-slot:[`item.empresa`]="{ item }">
                        <div
                          :class="{
                            'selected-row':
                              selectedCif?.empresa === item.empresa,
                          }"
                          style="display: flex; justify-content: space-between"
                        >
                          {{ companyName(item.empresa) }}
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </div>
          <div v-else-if="isAcceptedBtn">
            <v-card-subtitle
              :class="['text-body-1', isMobile ? 'ml-4' : 'ml-8']"
            >
              {{ $t('editNifDialog.message') }}
            </v-card-subtitle>
            <div
              :class="[
                'text-body-1',
                'd-flex',
                'justify-center',
                'align-center',
                'mt-6',
                ,
                isMobile ? 'ml-4' : 'ml-16',
              ]"
            >
              <span
                style="
                  font-size: 1.8em;
                  vertical-align: middle;
                  margin-right: 4px;
                "
                >•</span
              >
              {{ $t('notificationProperties.receiver') }}:
              <v-list-item-content class="subtitle-1 ml-4">
                <span class="font-weight-black">
                  {{ selectedCif.numeroDocIdentidad }}
                </span>
                <span>{{ selectedCif.empresa }} </span>
              </v-list-item-content>
            </div>
          </div>

          <div v-if="isAcceptedBtn" class="mt-6">
            <v-card-subtitle
              :class="[
                'text-body-1',
                'd-flex',
                'align-center',
                isMobile ? 'ml-4' : 'ml-8',
              ]"
            >
              <span
                >{{
                  $t('editNifDialog.confirmMessage', {
                    text: $t('editNifDialog.confirmWord'),
                  })
                }}:</span
              >
              <v-text-field
                v-model="confirmationText"
                :label="$t('editNifDialog.confirmLabel')"
                class="ml-4 auto-width"
                dense
              ></v-text-field>
            </v-card-subtitle>
          </div>

          <div v-if="isIgnoredBtn" class="mt-6">
            <v-card-subtitle
              :class="[
                'text-body-1',
                'd-flex',
                'align-center',
                isMobile ? 'ml-4' : 'ml-8',
              ]"
            >
              <span
                >{{
                  $t('editNifDialog.confirmMessage', {
                    text: $t('editNifDialog.ignoreWord'),
                  })
                }}:</span
              >
              <v-text-field
                v-model="confirmationText"
                :label="$t('editNifDialog.confirmLabel')"
                class="ml-4 auto-width"
                dense
              ></v-text-field>
            </v-card-subtitle>
          </div>

          <v-card-actions
            class="mt-4 d-flex flex-wrap justify-start"
            :class="{ 'justify-center': isMobile }"
          >
            <div v-if="showIgnoreButton" :class="[isMobile ? 'ml-1' : 'ml-8']">
              <v-btn
                v-if="!isAcceptedBtn && !isIgnoredBtn"
                color="primary"
                text
                @click="ignore"
                :disabled="loading"
              >
                {{ $t('editNifDialog.ignore') }}
              </v-btn>
            </div>

            <v-spacer v-if="!isMobile"></v-spacer>

            <v-btn
              v-if="!isAcceptedBtn && !isIgnoredBtn"
              color="primary"
              text
              @click="accept"
              :disabled="loading || !selectedCif"
              :class="{ 'mr-2 mb-2': isMobile }"
            >
              {{ $t('editNifDialog.accept') }}
            </v-btn>

            <v-btn
              v-if="isAcceptedBtn"
              color="primary"
              text
              @click="confirm"
              :disabled="
                loading ||
                !selectedCif ||
                confirmationText !== $t('editNifDialog.confirmWord')
              "
              :class="{ 'mr-2 mb-2': isMobile }"
            >
              {{ $t('editNifDialog.confirm') }}
            </v-btn>

            <v-btn
              v-if="isIgnoredBtn"
              color="primary"
              text
              @click="confirm"
              :disabled="
                loading || confirmationText !== $t('editNifDialog.ignoreWord')
              "
            >
              {{ $t('editNifDialog.confirm') }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="cancel"
              :class="{ 'mr-2 mb-2': isMobile }"
            >
              {{ $t('editNifDialog.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showDialog: Boolean,
    cifs: {
      type: Array,
      default: () => [],
    },
    showIgnoreButton: Boolean,
  },
  data() {
    return {
      mustBeShow: this.showDialog,
      selectedCif: undefined,
      search: '',
      loading: true,
      isAcceptedBtn: false,
      isIgnoredBtn: false,
      confirmationText: '',
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    show() {
      return this.showDialog
    },
    headers() {
      return [
        {
          text: 'NIF',
          value: 'numeroDocIdentidad',
          width: '150px',
        },
        { text: this.$t('company'), value: 'empresa', width: '350px' },
      ]
    },
    filteredCifs() {
      if (!this.cifs.length) return
      return this.cifs.filter((item) => {
        // String de búsqueda en mayúscula
        const searchCap = this.search ? this.search.toUpperCase() : ''
        const { empresa, numeroDocIdentidad } = item
        // Guardamos los elementos en mayúscula
        let dataCap = [empresa?.toUpperCase(), numeroDocIdentidad]
        // Filtramos
        for (let i = 0; i < dataCap.length; i++)
          if (dataCap[i]?.includes(searchCap)) return item
      })
    },
  },
  mounted() {
    this.loading = false
  },

  methods: {
    cancel() {
      this.selectedCif = undefined
      this.mustBeShow = false
      this.$emit('edit-nif-dialog-cancel')
    },
    confirm() {
      this.mustBeShow = false
      this.$emit(
        'edit-nif-dialog-confirm',
        this.selectedCif
          ? this.selectedCif.numeroDocIdentidad
          : this.ignoreWordAlwaysInSpanish()
      )
    },
    accept() {
      this.isAcceptedBtn = true
    },
    ignore() {
      this.selectedCif = undefined
      this.isIgnoredBtn = true
    },
    selectedRow(row) {
      this.selectedCif = {
        empresa: row.empresa,
        numeroDocIdentidad: row.numeroDocIdentidad,
      }
    },
    companyName(name) {
      return name.length > 30 ? name.substring(0, 28) + '...' : name
    },
    ignoreWordAlwaysInSpanish() {
      return this.$i18n.t('editNifDialog.ignoreWord', 'es') || 'IGNORAR'
    },
  },
  watch: {
    search: function (newValue) {
      if (newValue) {
        this.selectedCif = undefined
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selected-row {
  font-weight: bold;
  background-color: #f0f0f0;
}

.auto-width {
  width: auto;
  max-width: 130px;
}
</style>
