var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.title'))+" ")]),(!_vm.isAcceptedBtn && !_vm.isIgnoredBtn)?_c('div',[_c('v-card-subtitle',{staticClass:"text-h7 d-flex justify-center align-center"},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.subtitle'))+" ")]),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pr-8",attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"data-v-step":"104","clearable":"","prepend-icon":"mdi-magnify","label":_vm.$t('searchField'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mx-4 px-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredCifs,"items-per-page":8,"sort-by":'empresa',"sort-desc":false,"footer-props":{
                      'items-per-page-options': [8, 25, 50 /*-1*/],
                    }},on:{"click:row":_vm.selectedRow},scopedSlots:_vm._u([{key:`item.numeroDocIdentidad`,fn:function({ item }){return [_c('div',{class:{
                          'selected-row':
                            _vm.selectedCif?.numeroDocIdentidad ===
                            item.numeroDocIdentidad,
                        },staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._v(" "+_vm._s(item.numeroDocIdentidad)+" ")])]}},{key:`item.empresa`,fn:function({ item }){return [_c('div',{class:{
                          'selected-row':
                            _vm.selectedCif?.empresa === item.empresa,
                        },staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._v(" "+_vm._s(_vm.companyName(item.empresa))+" ")])]}}],null,true)})],1)],1)],1)]],2):(_vm.isAcceptedBtn)?_c('div',[_c('v-card-subtitle',{class:['text-body-1', _vm.isMobile ? 'ml-4' : 'ml-8']},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.message'))+" ")]),_c('div',{class:[
              'text-body-1',
              'd-flex',
              'justify-center',
              'align-center',
              'mt-6',
              ,
              _vm.isMobile ? 'ml-4' : 'ml-16',
            ]},[_c('span',{staticStyle:{"font-size":"1.8em","vertical-align":"middle","margin-right":"4px"}},[_vm._v("•")]),_vm._v(" "+_vm._s(_vm.$t('notificationProperties.receiver'))+": "),_c('v-list-item-content',{staticClass:"subtitle-1 ml-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.selectedCif.numeroDocIdentidad)+" ")]),_c('span',[_vm._v(_vm._s(_vm.selectedCif.empresa)+" ")])])],1)],1):_vm._e(),(_vm.isAcceptedBtn)?_c('div',{staticClass:"mt-6"},[_c('v-card-subtitle',{class:[
              'text-body-1',
              'd-flex',
              'align-center',
              _vm.isMobile ? 'ml-4' : 'ml-8',
            ]},[_c('span',[_vm._v(_vm._s(_vm.$t('editNifDialog.confirmMessage', { text: _vm.$t('editNifDialog.confirmWord'), }))+":")]),_c('v-text-field',{staticClass:"ml-4 auto-width",attrs:{"label":_vm.$t('editNifDialog.confirmLabel'),"dense":""},model:{value:(_vm.confirmationText),callback:function ($$v) {_vm.confirmationText=$$v},expression:"confirmationText"}})],1)],1):_vm._e(),(_vm.isIgnoredBtn)?_c('div',{staticClass:"mt-6"},[_c('v-card-subtitle',{class:[
              'text-body-1',
              'd-flex',
              'align-center',
              _vm.isMobile ? 'ml-4' : 'ml-8',
            ]},[_c('span',[_vm._v(_vm._s(_vm.$t('editNifDialog.confirmMessage', { text: _vm.$t('editNifDialog.ignoreWord'), }))+":")]),_c('v-text-field',{staticClass:"ml-4 auto-width",attrs:{"label":_vm.$t('editNifDialog.confirmLabel'),"dense":""},model:{value:(_vm.confirmationText),callback:function ($$v) {_vm.confirmationText=$$v},expression:"confirmationText"}})],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"mt-4 d-flex flex-wrap justify-start",class:{ 'justify-center': _vm.isMobile }},[(_vm.showIgnoreButton)?_c('div',{class:[_vm.isMobile ? 'ml-1' : 'ml-8']},[(!_vm.isAcceptedBtn && !_vm.isIgnoredBtn)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading},on:{"click":_vm.ignore}},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.ignore'))+" ")]):_vm._e()],1):_vm._e(),(!_vm.isMobile)?_c('v-spacer'):_vm._e(),(!_vm.isAcceptedBtn && !_vm.isIgnoredBtn)?_c('v-btn',{class:{ 'mr-2 mb-2': _vm.isMobile },attrs:{"color":"primary","text":"","disabled":_vm.loading || !_vm.selectedCif},on:{"click":_vm.accept}},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.accept'))+" ")]):_vm._e(),(_vm.isAcceptedBtn)?_c('v-btn',{class:{ 'mr-2 mb-2': _vm.isMobile },attrs:{"color":"primary","text":"","disabled":_vm.loading ||
              !_vm.selectedCif ||
              _vm.confirmationText !== _vm.$t('editNifDialog.confirmWord')},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.confirm'))+" ")]):_vm._e(),(_vm.isIgnoredBtn)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading || _vm.confirmationText !== _vm.$t('editNifDialog.ignoreWord')},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.confirm'))+" ")]):_vm._e(),_c('v-btn',{class:{ 'mr-2 mb-2': _vm.isMobile },attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('editNifDialog.cancel'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }