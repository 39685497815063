<template>
  <div id="certsAlertAccept">
    <v-dialog
      v-model="show"
      persistent
      width="860"
      max-width="860"
      min-width="860"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{
            errorData.length > 1
              ? $t('certificatesAlert.alertCertificateTitlePlural')
              : $t('certificatesAlert.alertCertificateTitle', {
                  cif: cif,
                  empresa: empresaName,
                })
          }}
        </v-card-title>

        <v-card-text class="mt-6 text-h6">
          <!-- Tabla que muestra los errores de certificado -->
          <div v-if="errorData.length > 1" v-html="generateErrorTable()"></div>
          <!--Si es uno solo, texto nada mas -->
          <div v-else>
            <div>
              <strong>Error: {{ errorData[0]?.codigoError || 'N/A' }}</strong> -
              {{
                errorData[0]?.firebaseError?.description ||
                'No description available'
              }}
              <br />
              <br />
              <div v-html="errorData[0]?.firebaseError?.help"></div>
              <br />
              <br />
              <a :href="errorData[0]?.firebaseError?.link" target="_blank">
                {{
                  errorData[0]?.firebaseError?.link || 'No link available'
                }}</a
              >
            </div>
          </div>
        </v-card-text>

        <v-card-text class="mt-6 consent-section">
          <!-- Checkbox with label -->
          <v-checkbox
            v-model="deleteErrorWarn"
            class="large-checkbox"
          ></v-checkbox>
          <!-- Label with link -->
          <span class="large-checkbox-label">{{
            $t('certificatesAlert.alertCertificateMSG')
          }}</span>
        </v-card-text>

        <v-card-actions class="mt-4 justify-start">
          <v-btn
            :disabled="loading"
            color="primary"
            text
            @click="acceptCertAlertWarn"
          >
            {{ $t('certificatesAlert.accept') }}
            <template v-slot:loader>
              <v-progress-circular indeterminate size="20" width="2" />
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updateUserInfo } from '@/services/user-service'
import { Firestore } from '@/firebase-exports'

export default {
  data() {
    return {
      show: false, // Inicialmente el diálogo está cerrado
      deleteErrorWarn: false, // Si el usuario desactiva los warnings
      loading: false,
      cif: {}, // CIF del usuario
      errorData: [], // Array de errores de certificado
      empresaName: '',
    }
  },
  mounted() {
    // Abrimos el diálogo cuando el componente se monta
    this.show = true
    this.cif = this.$store.state.user.lastCertificateErrorData[0].cif
    this.errorData = this.$store.state.user.lastCertificateErrorData || []
    this.empresaName = this.$store.state.user.nameEmpresa
    const cifsCollection = this.$store.state.user.cifs || {}
    const cifDoc = Object.values(cifsCollection).find(
      (doc) => doc.numeroDocIdentidad === this.cif
    )
    if (cifDoc && cifDoc.empresa) {
      this.empresaName = cifDoc.empresa
    }
  },
  methods: {
    async acceptCertAlertWarn() {
      try {
        this.loading = true

        // Si el checkbox está activado, actualiza lastCertificateError
        if (this.deleteErrorWarn) {
          // Elimina el atributo 'lastCertificateError'
          await updateUserInfo(this.$store.state.user.id, {
            lastCertificateError: Firestore.deleteField(),
            lastCertificateErrorData: Firestore.deleteField(),
          })
        }

        this.loading = false
        this.show = false // Cierra este diálogo modal
      } catch (error) {
        this.loading = false
        this.show = false // Cierra este diálogo modal
        console.log(error)
      }
    },
    generateErrorTable() {
      if (
        !this.errorData ||
        !Array.isArray(this.errorData) ||
        !this.errorData.length
      )
        return 'No error data available'

      let tableHTML = `
    <table style="width:100%; border-collapse: collapse">
      <thead>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px;background-color: var(--v-primary-base);color:#FFFFFF">CIF</th>
          <th style="border: 1px solid #ddd; padding: 8px;background-color: var(--v-primary-base);color:#FFFFFF">Error</th>
          <th style="border: 1px solid #ddd; padding: 8px;background-color: var(--v-primary-base);color:#FFFFFF">Descripción</th>
          <th style="border: 1px solid #ddd; padding: 8px;background-color: var(--v-primary-base);color:#FFFFFF">Ayuda</th>
          <th style="border: 1px solid #ddd; padding: 8px;background-color: var(--v-primary-base);color:#FFFFFF">Link</th>
        </tr>
      </thead>
      <tbody>
  `

      this.errorData.forEach((error) => {
        const codigoError = error.codigoError || 'N/A'

        // Truncar la descripción, ayuda y link a 15 caracteres y agregar el tooltip
        const description = error.firebaseError?.description
          ? error.firebaseError.description.slice(0, 15) + '...'
          : 'No description'
        const fullDescription =
          error.firebaseError?.description || 'No description available'

        const help = error.firebaseError?.help
          ? error.firebaseError.help.slice(0, 15) + '...'
          : 'No help'
        const fullHelp = error.firebaseError?.help || 'No help available'

        const link = error.firebaseError?.link
          ? `<a href="${
              error.firebaseError.link
            }" target="_blank" style="color: #1a73e8;">${error.firebaseError.link.slice(
              0,
              15
            )}...</a>`
          : 'No link'
        const fullLink = error.firebaseError?.link || 'No link available'

        // Generar HTML para cada fila con tooltip en description, help y link
        tableHTML += `
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px;">${
          error.cif || 'N/A'
        }</td>
        <td style="border: 1px solid #ddd; padding: 8px;">${codigoError}</td>
        <td style="border: 1px solid #ddd; padding: 8px;">
          <div class="tooltip">${description}<span class="tooltiptext">${fullDescription}</span></div>
        </td>
        <td style="border: 1px solid #ddd; padding: 8px;">
          <div class="tooltip">${help}<span class="tooltiptext">${fullHelp}</span></div>
        </td>
        </td>
        <td style="border: 1px solid #ddd; padding: 8px;">
          <div class="tooltip">${link}<span class="tooltiptext">${fullLink}</span></div>
        </td>
      </tr>
    `
      })

      tableHTML += `</tbody></table>`

      return tableHTML
    },
  },
}
</script>

<style>
.consent-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.large-checkbox .v-input--selection-controls__input {
  font-size: 1.2rem;
}

.large-checkbox-label {
  font-size: 1.2rem;
}

.v-card-actions {
  display: flex;
  justify-content: flex-start;
}

.v-btn {
  margin-right: 10px;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  word-break: break-word;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: normal; /* Permite el ajuste en varias líneas */
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto; /* Activar desplazamiento si el contenido es largo */
  max-height: 200px; /* Altura máxima para evitar desbordamientos verticales */

  /* Ancho mayor que la columna, pero limitado */
  min-width: 450px; /* Ancho mínimo del tooltip */
  max-width: 450px; /* Ancho máximo para evitar desbordamiento */
  width: auto; /* Ajuste dinámico basado en el contenido */

  box-sizing: border-box; /* Asegurar que el padding y borde se incluyan en el ancho total */

  /* Posicionamiento */
  top: 100%; /* Mostrar debajo de la celda */
  left: 50%; /* Posicionar a la mitad de la celda */
  transform: translateX(
    -70%
  ); /* Mover el tooltip más a la izquierda (ajustado) */
}

/* Mostrar el tooltip al hacer hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Flecha en el tooltip */
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: -5px; /* Flecha apuntando hacia arriba */
  left: 50%; /* Flecha centrada respecto al tooltip */
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
</style>
